import SearchBar from 'components/SearchBar/SearchBar';
import { SearchSettingsContext } from 'context';
import styles from 'components/SearchBar/SearchBar.module.scss';
import { unstable_Form as Form } from 'reakit/Form';
import React from 'react';

import classNames from 'classnames';
import { DialogDisclosure } from 'reakit/Dialog';

const SearchBarWrapper = ({
  disclosure,
  isExpandable,
  form: searchForm,
  initialState,
  ...props
}) => {
  const context = React.useContext(SearchSettingsContext);
  const form = searchForm || context?.form;

  const Tag = disclosure && props.variant === 'view' ? DialogDisclosure : 'div';
  const tagProps =
    disclosure && props.variant === 'view' ? { ...disclosure, as: 'div' } : {};
  return (
    <Form
      className={classNames(styles.form, {
        [styles.formViewWrapper]: props.variant === 'view',
      })}
      {...form}
    >
      <Tag
        {...tagProps}
        className={classNames(styles.searchbarWrapper, {
          [styles.viewWrapper]: props.variant === 'view',
        })}
      >
        <SearchBar
          disclosure={disclosure}
          variant="view"
          {...props}
          form={form}
          isExpandable
          initialState={initialState}
        />
      </Tag>
    </Form>
  );
};
export default SearchBarWrapper;
