import AdminLoginModal from 'components/AdminLoginModal/AdminLoginModal';
import styles from 'components/Footer/Footer.module.scss';
import Container from 'components/Container/Container';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const footerImprintItems = [
    {
      id: 'service',
      text: 'Terms of service',
      link: '/terms-of-service',
    },
    {
      id: 'privacy',
      text: 'Data privacy statement',
      link: '/data-privacy',
    },
  ];

  const sponsorImages = [
    {
      id: 'asia',
      Icon: IconComponents.AsiaIcon,
      alt: 'asia',
      href: 'https://asia-spinalinjury.org/',
    },
    {
      id: 'scope',
      Icon: IconComponents.ScopeIcon,
      alt: 'scope',
      href: 'https://scope-sci.org/',
    },
  ];

  return (
    <div className={styles.container}>
      <Heading
        theme="contrast"
        size="h2"
        as="h4"
        className={styles.footerTitle}
      >
        Proudly supported by
      </Heading>
      <div className={styles.sponsorWrapper}>
        <Link
          href="https://www.wingsforlife.com/en/"
          target="_blank"
          asType="external"
          variant="noUnderline"
          rel="noreferrer"
          className={styles.footerLink}
        >
          <IconWrapper
            Icon={IconComponents.LogoWingsWhite}
            alt="Wings for life logo"
            className={styles.wingsImage}
          />
        </Link>
        <div className={styles.imgWrapper}>
          {sponsorImages.map(item => {
            return (
              <Link
                asType="external"
                href={item.href}
                target="_blank"
                rel="noreferrer"
                variant="noUnderline"
                key={item.id}
              >
                <IconWrapper
                  Icon={item.Icon}
                  alt={item.alt}
                  className={styles.image}
                />
              </Link>
            );
          })}
        </div>
      </div>

      <div className={styles.imprintWrapper}>
        <Container className={styles.imprintContainer}>
          <div className={styles.meta}>
            <div className={styles.loginTrialsWrapper}>
              <div>
                <AdminLoginModal />
              </div>
              <div className={styles.allTrialsTable}>
                <Link
                  type="ui"
                  size="75"
                  variant="noUnderline"
                  theme="contrast"
                  className={styles.trialsTableLink}
                  to="/all-trials"
                >
                  <IconWrapper
                    Icon={IconComponents.TrialsIcon}
                    className={styles.trialsTableIcon}
                  />
                  <span className={styles.trialsTableLinkText}>
                    Trial table
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <nav className={styles.imprintItems}>
            <span className={styles.copyRight}>
              © {currentYear} SciTrialsFinder.net
            </span>
            {footerImprintItems.map(item => {
              return (
                <Link
                  type="ui"
                  size="75"
                  variant="noUnderline"
                  theme="contrast"
                  className={styles.imprintLink}
                  to={item.link}
                  key={item.id}
                >
                  {item.text}
                </Link>
              );
            })}
          </nav>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
