import DataView from './DataView/DataView';
import useTranslation from 'hooks/useTranslation';
import styles from 'components/SearchBar/SearchBar.module.scss';
import Client from 'api/client';
import useMediaQuery from 'hooks/useMediaQuery';
import TimeSinceInjury from 'components/TimeSinceInjury/TimeSinceInjury';
import InjurySeverity from 'components/InjurySeverity/InjurySeverity';
import InjuryLevel from 'components/InjuryLevel/InjuryLevel';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMenuState, MenuItem } from 'reakit/Menu';
import { useDebounceCallback } from '@react-hook/debounce';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import classNames from 'classnames';
import Button from '@moonshineragency/ui/src/components/Button/Button';

import {
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormInput as FormInput,
} from 'reakit/Form';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';

const SearchBarContext = React.createContext();

const pushMatomoEvent = (value, name) => {
  // eslint-disable-next-line no-underscore-dangle
  const _paq = window._paq || [];
  _paq.push([
    'trackEvent',
    'Select location, severity and time since injury',
    `Selected ${
      typeof value === 'object' ? value.label : value
    } within ${name}`,
    'Trial location, severity and time since injury',
  ]);
};

const SearchSelect = ({
  popover,
  items,
  form,
  name,
  isInputFocused,
  setIsInputFocused,
}) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const menu = useMenuState();

  useEffect(() => {
    if (isInputFocused) {
      document.getElementById('option-0')?.focus();
      setIsInputFocused(false);
    }
  }, [isInputFocused]);

  const handleItemClick = (item, e) => {
    e.preventDefault();
    const locationValues = {
      label: item?.label,
      value: item?.value?.value,
    };
    form.update(name, locationValues);
    form.update('location_name', locationValues.label);
    pushMatomoEvent(locationValues, name);

    popover.hide();
    menu.hide();
    setIsInputFocused(false);
    document.getElementById('injury-level-selector')?.focus();
  };

  return (
    <>
      <div
        className={classNames(styles.dropdownContainer, {
          [styles.dropdownHasItems]: items.length > 0,
        })}
        id="location-search"
        role="listbox"
        tabIndex="-1"
      >
        {items.map((item, index) => (
          <MenuItem
            key={`${item.value.value?.lat},${item.value.value?.lng}`}
            id={`option-${index}`}
            role="option"
            disabled={item.isDisabled ?? false}
            {...menu}
            onClick={e => handleItemClick(item, e)}
            onChange={() => {
              if (!isDesktop) {
                popover.hide();
              }
            }}
            className={styles.searchDropdownLabel}
          >
            {item.label}
          </MenuItem>
        ))}
      </div>
    </>
  );
};

export const Selector = ({
  shortName,
  children,
  name,
  id,
  popover,
  showExpandIcon = true,
  // hasReset,
  label,
  isEmpty,
  hasManualTrigger,
  initialState,
}) => {
  const ItemTag = !hasManualTrigger ? PopoverDisclosure : 'div';
  const itemProps = !hasManualTrigger ? { ...popover } : {};

  return (
    <div className={styles.item}>
      {popover && (
        <Popover className={styles.popOver} {...popover} aria-label={name}>
          {children}
        </Popover>
      )}

      <ItemTag
        as="div"
        className={styles.itemTag}
        id={id}
        {...itemProps}
        onClick={() => {
          // eslint-disable-next-line no-underscore-dangle
          const _paq = window._paq || [];
          _paq.push([
            'trackEvent',
            'Search bar',
            `Search ${name} clicked and search trial informations`,
            'Trial',
          ]);
        }}
      >
        {/** check polite */}
        <span aria-label="You selected" aria-live="polite">
          <div className={styles.label}>{name}</div>
          <div className={styles.dropdownWrapper}>
            <div className={styles.textLabel}>
              {label}
              {isEmpty === true && (
                <>
                  {initialState ? (
                    <div className={styles.emptyValue}>Select</div>
                  ) : (
                    <div className={styles.emptyValue}>Any</div>
                  )}
                </>
              )}
              {shortName && (
                <span className={styles.shortName}>{shortName}</span>
              )}
              {popover && (
                <PopoverDisclosure
                  tabIndex={-1}
                  {...popover}
                  disabled={popover.visible}
                  className={classNames(styles.expandButton, {
                    [styles.hideExpandButton]: !showExpandIcon,
                  })}
                >
                  <IconComponents.SmallArrowDown
                    className={styles.expandButtonIcon}
                  />
                </PopoverDisclosure>
              )}
            </div>
            {/* {popover && (
              <BaseButton
                className={styles.resetButton}
                disabled={!hasReset || !popover.visible}
                aria-label="Reset Injury Level"
              >
                <IconComponents.SmallCrossXIcon />
              </BaseButton>
            )} */}
          </div>
        </span>
      </ItemTag>
    </div>
  );
};
const Location = ({ form, dialog, isExpandable, initialState }) => {
  const popover = usePopoverState();
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const [suggestions, setSuggestions] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const locationInputRef = useRef();

  useEffect(() => {
    if (popover.visible) {
      locationInputRef?.current?.focus();
    }

    if (!popover.visible) {
      setSuggestions([]);
    }

    if (!isDesktop && !popover.visible) {
      locationInputRef.current.blur();
    }
  }, [popover.visible]);

  const getLocationsSuggestions = async (
    field,
    showShortLabel = false,
    showAllCategories = false,
  ) => {
    popover.show();
    const {
      data: { results },
    } = await Client.search.location(field);
    const filteredSearchResults = showAllCategories
      ? results
      : results.filter(
          ({ components }) =>
            /* eslint no-underscore-dangle: ["error", { "allow": ["_category"] }] */
            components._category === 'place' ||
            components._category === 'road' ||
            components._category === 'health',
        );

    const searchResultOptions = filteredSearchResults.map(
      ({
        formatted,
        geometry: value,
        components: { city, county, state, country },
      }) => {
        const label = showShortLabel
          ? `${city ?? county ?? state}, ${country}`
          : formatted;
        return {
          label,
          key: label,
          value: { value, label },
        };
      },
    );

    setSuggestions(searchResultOptions);
    popover.show();
    return searchResultOptions;
  };

  const dispatchTextSearch = useDebounceCallback(async () => {
    if (!form.values.location_name) {
      return;
    }
    try {
      await getLocationsSuggestions(form.values.location_name);
    } catch (e) {
      console.error(e);
    }
  }, 300);

  const dispatchGeolocationSearch = () => {
    const label = 'Loading your location...';
    setSuggestions([
      {
        label,
        key: label,
        value: { value: null, label },
        isDisabled: true,
      },
    ]);
    popover.show();

    navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        if (form.values.location_name) {
          return;
        }

        try {
          await getLocationsSuggestions(`${latitude} ${longitude}`, true, true);
        } catch (e) {
          console.error(e);
        }
      },
    );
  };

  useEffect(() => {
    if (
      form?.values?.location_name !== form?.values?.location?.label &&
      form?.values?.location?.label
    ) {
      if (form?.values?.location_name) {
        // update displayed location_name
        form.update('location_name', form?.values?.location.label);
        popover.hide();
      } else {
        form.update('location', null);
        popover.hide();
      }
    }
  }, [form?.values?.location]);

  const handleInputChange = ({ currentTarget }) => {
    form.update('location_name', currentTarget.value);
    if (currentTarget.value) {
      // input field is not empty
      if (isExpandable) {
        dialog?.show();
      }
      dispatchTextSearch();
    } else {
      // input field is empty
      form.update('location', null);
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === 40) {
      setIsInputFocused(true);
    }
  };

  const locationInput = (
    <>
      <span className={styles.locationInputSpan}>
        <FormInput
          className={styles.locationInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            if (popover.visible) {
              return;
            }
            if (isDesktop) {
              if (!form.values.location_name) {
                // no input provided --> geolocation search
                dispatchGeolocationSearch();
              }
            }
          }}
          onClick={() => {
            if (!isDesktop) {
              if (form.values.location_name) {
                // text search
                dispatchTextSearch();
              } else {
                // geolocation search
                dispatchGeolocationSearch();
              }
            }
          }}
          {...form}
          ref={locationInputRef}
          placeholder="Where do you live?"
          name="location_name"
          autoComplete="off"
          role="combobox"
          aria-autocomplete="both"
          aria-owns="location-search"
          aria-haspopup="listbox"
          aria-expanded={popover.visible}
        />
      </span>
    </>
  );
  return (
    <Selector
      popover={popover}
      hasManualTrigger
      label={locationInput}
      name="Location"
      id="location-selector"
      showExpandIcon={suggestions.length > 0}
      initialState={initialState}
    >
      <DataView
        popover={popover}
        name={locationInput}
        className={styles.dataView}
        noSpacing
      >
        <SearchSelect
          popover={popover}
          name="location"
          items={suggestions}
          form={form}
          isInputFocused={isInputFocused}
          setIsInputFocused={setIsInputFocused}
        />
      </DataView>
    </Selector>
  );
};

const InjuryLevelField = ({ form, initialState }) => {
  const popover = usePopoverState({ modal: false });

  const spineSections = [
    {
      id: 'cervical',
      name: 'Cervical',
      sections: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8'],
    },
    {
      id: 'thoracic',
      name: 'Thoracic',
      sections: [
        'T1',
        'T2',
        'T3',
        'T4',
        'T5',
        'T6',
        'T7',
        'T8',
        'T9',
        'T10',
        'T11',
        'T12',
      ],
    },
    { id: 'lumbar', name: 'Lumbar', sections: ['L1', 'L2', 'L3', 'L4', 'L5'] },
    { id: 'sacral', name: 'Sacral', sections: ['S1', 'S2', 'S3', 'S4', 'S5'] },
  ];
  let spineOrder = [];
  spineSections.forEach(({ sections }) => {
    sections.forEach(section => {
      spineOrder = [...spineOrder, section];
    });
  });

  return (
    <Selector
      shortName="injury level"
      popover={popover}
      label={
        <>
          {form?.values?.injuryLevel && (
            <span className={styles.text}>
              {form.values.injuryLevel[0]}
              {form.values.injuryLevel.length > 1 && (
                <>
                  <span>&nbsp;to</span> {form.values.injuryLevel[1]}
                </>
              )}
            </span>
          )}
          {form?.values?.injuryLevel?.length === 0 && <>Any</>}
        </>
      }
      name="Injury level"
      id="injury-level-selector"
      isEmpty={!form?.values?.injuryLevel}
      hasReset={form?.values?.injuryLevel}
      initialState={initialState}
    >
      <InjuryLevel form={form} popover={popover} />
    </Selector>
  );
};
const Severity = ({ form, initialState }) => {
  const { t } = useTranslation('trials');

  const popover = usePopoverState();

  return (
    <Selector
      popover={popover}
      label={
        form.values?.severity && (
          <span className={styles.text}>
            {t(`severity.${form.values?.severity}`)}
          </span>
        )
      }
      name="Severity (AIS)"
      id="severity-selector"
      shortName="severity"
      isEmpty={!form.values?.severity}
      hasReset={form.values?.severity !== null}
      initialState={initialState}
    >
      <InjurySeverity
        form={form}
        popover={popover}
        onClickEvent={pushMatomoEvent}
      />
    </Selector>
  );
};

const TimeSinceInjuryField = ({ form, initialState }) => {
  const popover = usePopoverState();
  const timeSinceInjury = [
    {
      id: 1,
      label: 'Any',
      value: 'any',
    },
    {
      id: 2,
      label: 'Under 48hrs',
      value: 'under-48h',
    },
    {
      id: 3,
      label: '48 hrs - 6 months',
      value: '48h-6mon',
    },
    {
      id: 4,
      label: 'over 6 months',
      value: 'over-6mon',
    },
  ];
  return (
    <Selector
      shortName="time since injury"
      popover={popover}
      label={
        <>
          {form.values.time_since_injury !== null && (
            <span className={styles.text}>
              {
                timeSinceInjury.find(
                  val => val.value === form.values?.time_since_injury,
                )?.label
              }
            </span>
          )}
        </>
      }
      name="Time since injury"
      id="time-since-injury-selector"
      isEmpty={!form.values?.time_since_injury}
      hasReset={form.values.time_since_injury !== null}
      initialState={initialState}
    >
      <TimeSinceInjury
        form={form}
        popover={popover}
        onClickEvent={pushMatomoEvent}
      />
    </Selector>
  );
};

const SearchBar = React.forwardRef(
  (
    {
      className,
      variant,
      form = null,
      disclosure,
      isExpandable,
      initialState,
      ...other
    },
    ref,
  ) => {
    const expandedVariants = ['view', 'expanded'];
    if (!form) {
      return null;
    }

    const resetValues = () => {
      form.update('distance', {});
      form.update('include_uncurated', false);
      form.update('interventions', []);
      form.update('is_recruiting_now_soon', false);
      form.update('potential_benefits', []);
      form.update('trial_type', []);
    };

    return (
      <SearchBarContext.Provider value={{ variant }}>
        <div
          className={classNames(className, styles[variant], styles.container)}
          ref={ref}
          id="searchbar"
          {...other}
        >
          <div
            className={classNames(styles.searchContainer, styles[variant], {
              [styles.full]: expandedVariants.includes(variant),
            })}
          >
            {expandedVariants.includes(variant) && (
              <>
                <Location
                  form={form}
                  dialog={disclosure}
                  isExpandable={isExpandable}
                  initialState={initialState}
                />
                <div className={styles.separator} />

                <InjuryLevelField form={form} initialState={initialState} />
                <div className={styles.separator} />

                <Severity form={form} initialState={initialState} />
                <div className={styles.separator} />

                <TimeSinceInjuryField form={form} initialState={initialState} />
              </>
            )}
          </div>
          <FormSubmitButton
            as={Button}
            size="small"
            onClick={() => {
              if (!disclosure) {
                return;
              }
              resetValues();
              disclosure.hide();
            }}
            className={classNames(styles.submit)}
            shape={variant === 'view' ? 'bubble' : 'pill'}
          >
            <IconWrapper
              Icon={IconComponents.SearchIconSmall}
              className={styles.icon}
            />
            {variant !== 'view' && Object.keys(form.touched).length > 0 && (
              <span className={styles.searchButtonText}>Search</span>
            )}
          </FormSubmitButton>
        </div>
      </SearchBarContext.Provider>
    );
  },
);

SearchBar.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['minimal', 'view', 'expanded']),
};
SearchBar.defaultProps = {
  className: null,
  variant: 'view',
};

export default SearchBar;
