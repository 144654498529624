import styles from 'components/Layout/Layout.module.scss';
import AdminHeader from 'components/AdminNavigation/AdminNavigation';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';
import Seo from 'components/Seo/Seo';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Layout = ({
  isAdmin,
  hideSearch = false,
  isWide,
  children,
  className,
  layoutStructure,
  title,
  description,
  onlyShowSearchIcon = false,
  skipLinks = [],
  isContentpage = false,
}) => {
  const shortenedDescription =
    description && description.length > 149
      ? `${description.slice(0, 146)}...`
      : description;

  return (
    <div className={className}>
      <Seo title={title} description={shortenedDescription} />
      <nav id="skiplinks" className={styles.skipLinks} aria-label="Skiplinks">
        {[
          {
            id: 'content',
            title: 'Main Content',
          },
          ...skipLinks,
        ].map(({ id, title: skipTitle }) => (
          <a className={styles.skipLink} href={`#${id}`}>
            {skipTitle}
          </a>
        ))}
      </nav>
      {isAdmin && <AdminHeader />}
      {!isAdmin && (
        <Navigation
          hideSearch={hideSearch}
          isWide={isWide}
          onlyShowSearchIcon={onlyShowSearchIcon}
          reduced={isContentpage}
        />
      )}
      <div
        className={classNames(styles.main, {
          [styles.offsetHeader]: !isAdmin,
        })}
      >
        <main
          className={classNames(styles.content, {
            [styles.withoutNavigation]: layoutStructure.hideNavigation,
          })}
          id="content"
        >
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  layoutStructure: PropTypes.shape({
    hideNavigation: PropTypes.bool,
  }),
};

Layout.defaultProps = {
  isAdmin: false,
  className: null,
  layoutStructure: {
    hideNavigation: false,
  },
};

export default Layout;
