import styles from 'components/AdminNavigation/AdminNavigation.module.scss';
import useStore from 'store';
import PrivateElement from 'pages/admin/components/PrivateElement.js';
import { AuthorizationContextProvider } from 'contextProvider';
import { usePopoverState, PopoverDisclosure, Popover } from 'reakit/Popover';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React, { useEffect, useState } from 'react';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import { navigate } from 'gatsby';
import Image from '@moonshineragency/ui/src/components/Image/Image';
import logo from '@moonshineragency/ui/src/assets/tokens/icons/images/sci-logo.png';

const Navigation = () => {
  const store = useStore();
  const [url, setUrl] = useState('');
  const popover = usePopoverState();
  useEffect(() => {
    const path = window.location.href.split('/')[4];
    setUrl(path);
  }, []);

  const handleLogout = () => {
    popover.hide();
    store.removeUser();
    navigate('/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.linkWrapper}>
          <Link variant="noUnderline" to="/" className={styles.logoWrapper}>
            <Image
              alt="SCI logo"
              height="32"
              src={logo}
              className={styles.logo}
            />
          </Link>
          <Link
            type="navigation"
            variant="noUnderline"
            activeClassName={styles.active}
            className={`${styles.item} ${
              url === 'trialDetail' ? styles.activeLink : null
            }`}
            to="/admin/trials/list"
          >
            <span className={styles.itemInner}>
              <IconWrapper
                Icon={IconComponents.TrialsIcon}
                alt="logo"
                className={styles.logoImg}
              />
              Trials
            </span>
          </Link>
          <AuthorizationContextProvider>
            <PrivateElement scope="manage users">
              <Link
                type="navigation"
                variant="noUnderline"
                activeClassName={styles.active}
                className={styles.item}
                to="/admin/users/list"
              >
                <span className={styles.itemInner}>
                  <IconWrapper
                    Icon={IconComponents.UsersCircleIcon}
                    alt="users"
                    className={styles.logoImg}
                  />
                  Users
                </span>
              </Link>
            </PrivateElement>
          </AuthorizationContextProvider>
          <Link
            type="navigation"
            variant="noUnderline"
            activeClassName={styles.active}
            className={styles.item}
            to="https://scitrialsfinder.matomo.cloud/"
          >
            <span className={styles.itemInner}>
              <IconWrapper
                Icon={IconComponents.Dashboard}
                alt="users"
                className={styles.logoImg}
              />
              Analytics
            </span>
          </Link>
        </div>
        <div className={styles.selectorWrapper}>
          <PopoverDisclosure
            {...popover}
            toggle={() => {
              popover.toggle();
            }}
            name="dropdown"
            className={styles.selector}
          >
            {`${store?.user?.user?.first_name} ${store?.user?.user?.last_name}`}
            <IconComponents.SmallArrowDown
              className={styles.expandButtonIcon}
            />
          </PopoverDisclosure>
          <Popover
            {...popover}
            aria-label="Dropdown"
            className={styles.dropdownWrapper}
          >
            <div className={styles.label}>
              <Button theme="link" onClick={() => handleLogout()}>
                Logout
              </Button>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Navigation);
