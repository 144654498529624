import Card from '@moonshineragency/ui/src/components/Card/Card';
import styles from '@moonshineragency/ui/src/components/Modal/Modal.module.scss';
import cross from '@moonshineragency/ui/src/assets/tokens/icons/icons-small/smallCrossX.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { Dialog, DialogBackdrop } from 'reakit/Dialog';
import { Button } from 'reakit/Button';
import { usePreventScroll } from '@moonshineragency/ui/src/hooks/usePreventScroll';
import Image from '@moonshineragency/ui/src/components/Image/Image';

const Modal = ({
  dialog,
  header,
  children,
  theme,
  scrollDisabled,
  contentClassName,
  i18n,
  actions,
  size,
  hideOnClickOutside,
  tabIndex,
  finalFocusRef,
  className,
  onClick,
}) => {
  usePreventScroll(dialog.visible);

  return (
    <>
      <DialogBackdrop className={styles.backdrop} {...dialog}>
        <Dialog
          {...dialog}
          preventBodyScroll={false} // disable built-in preventBodyScroll from reakit
          aria-label={i18n.dialog}
          hideOnClickOutside={hideOnClickOutside}
          tabIndex={tabIndex || undefined}
          unstable_finalFocusRef={finalFocusRef}
        >
          <Card
            className={classNames(
              styles.card,
              {
                [styles.large]: size === 'lg',
              },
              className,
            )}
          >
            <div
              className={classNames(styles.header, styles[theme], {
                [styles.withClose]: !actions,
              })}
            >
              {header}
              {!actions && (
                <Button
                  className={styles.close}
                  onClick={onClick || dialog.hide}
                  as="div"
                  aria-label="Dialog schließen"
                >
                  <Image src={cross} />
                </Button>
              )}
              {actions && <div className={styles.actions}>{actions}</div>}
            </div>
            <div
              className={classNames(styles.content, contentClassName, {
                [styles.scrollDisabled]: scrollDisabled,
              })}
            >
              {children}
            </div>
          </Card>
        </Dialog>
      </DialogBackdrop>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  /** reakit dialog instance */
  dialog: PropTypes.shape({}).isRequired,
  size: PropTypes.oneOf([null, 'lg']),
  header: PropTypes.node,
  i18n: PropTypes.shape({
    dialog: PropTypes.string,
  }),
  theme: PropTypes.oneOf(['default', 'secondary']),
  hideOnClickOutside: PropTypes.bool,
  tabIndex: PropTypes.number,
};
Modal.defaultProps = {
  i18n: {
    dialog: 'Modal',
  },
  size: null,
  header: null,
  theme: 'default',
  hideOnClickOutside: true,
  tabIndex: null,
};
export { Modal as default };
