import styles from './AdminResetPassword.module.scss';
import Client from 'api/client';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';

const AdminResetPassword = ({ setIsPasswordForgotten, changeModalHeader }) => {
  const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);

  useEffect(() => {
    if (isEmailSentSuccess) {
      changeModalHeader('');
    } else {
      changeModalHeader('Forgot your password?');
    }
  });

  const form = useFormState({
    values: {
      email: '',
    },
    onSubmit: async values => {
      try {
        await Client.forgotPassword.post({ email: values.email });
      } catch (e) {
        let errors = {};

        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter valid email address'),
        };

        if (Object.keys(errors).length) {
          throw errors;
        }
      }
      setIsEmailSentSuccess(true);
    },
    onValidate: values => {
      let errors = {};
      if (!values.email) {
        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter email'),
        };
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    },
  });

  const submitArea = (
    <div className={styles.actionButtonsWrapper}>
      <Button
        theme="link"
        className={styles.actionTextLink}
        onClick={() => setIsPasswordForgotten(false)}
      >
        Login
      </Button>

      <FormSubmitButton
        as={Button}
        theme="primary"
        size="default"
        {...form}
        className={styles.resetPasswordButton}
      >
        Send password reset link
      </FormSubmitButton>
    </div>
  );

  return (
    <div>
      {isEmailSentSuccess ? (
        <>
          <div className={styles.emailSentWrapper}>
            <span className={styles.emailFastIconWrapper}>
              <IconWrapper
                Icon={IconComponents.LargeEmaiLFastIcon}
                alt="login"
                className={styles.emailFastIcon}
              />
            </span>
            <h2 className={styles.emailSentHeader}>Check your inbox</h2>
            <p className={styles.emailSentInformation}>
              We’ve sent a password reset link to{' '}
              <span className={styles.emailSentEmailAddress}>
                {form.values.email}{' '}
              </span>{' '}
              (remember to check your Spam).
            </p>
            <Button
              theme="link"
              className={styles.emailSentLoginLink}
              onClick={() => setIsPasswordForgotten(false)}
            >
              Login
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>
            No worries, we’ll get you a new one. Just enter your email address
            and we’ll send you a link to reset your password.
          </p>
          <Form hasGeneralFormErrorMessage submitArea={submitArea} form={form}>
            <InputField
              {...form}
              name="email"
              label="Your email address"
              noMessage
            />
            <FormMessage {...form} name="email" />
          </Form>
        </>
      )}
    </div>
  );
};

AdminResetPassword.propTypes = {
  setIsPasswordForgotten: PropTypes.func.isRequired,
  changeModalHeader: PropTypes.func.isRequired,
};

export default AdminResetPassword;
