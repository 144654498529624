import styles from 'components/Spine/Spine.module.scss';
import React, { useRef, useState, useLayoutEffect } from 'react';
import classNames from 'classnames';

const vertebrae = {
  C1: 0,
  C2: 1,
  C3: 2,
  C4: 3,
  C5: 4,
  C6: 5,
  C7: 6,
  C8: 6,
  T1: 7,
  T2: 8,
  T3: 9,
  T4: 10,
  T5: 11,
  T6: 12,
  T7: 13,
  T8: 14,
  T9: 15,
  T10: 16,
  T11: 17,
  T12: 18,
  L1: 19,
  L2: 20,
  L3: 21,
  L4: 22,
  L5: 23,
  S1: 24,
  S2: 24,
  S3: 24,
  S4: 24,
  S5: 24,
};

const Spine = ({ className, first, last, isInteractive, isOpen }) => {
  const containerRef = useRef(null);
  const startElementRef = useRef(null);
  const [position, setPosition] = useState(0);

  const firstIndex = first === 'any' ? 0 : vertebrae[first];
  const lastIndex = last === 'any' ? 25 : vertebrae[last];

  useLayoutEffect(() => {
    if (isInteractive) {
      return;
    }
    if (!containerRef.current) {
      return;
    }
    const startRect = startElementRef?.current?.getBoundingClientRect();

    const firstElement = containerRef?.current.querySelector('.first');
    const firstRect = firstElement?.getBoundingClientRect();

    const secondElement = containerRef?.current.querySelector('.last');
    const secondRect = secondElement?.getBoundingClientRect();

    const topDifference = firstRect?.top - startRect?.top;
    setPosition(
      (topDifference + (secondRect?.bottom - firstRect?.top - 17) / 2).toFixed(
        2,
      ),
    );
  }, [first, last, containerRef.current, startElementRef.current, isOpen]);

  return (
    <div tabIndex="-1" className={classNames(styles.spine, className)}>
      {!isInteractive && (
        <div
          className={classNames(
            { [styles.hidden]: first === 'any' },
            styles.indicator,
          )}
          style={{ top: `${position}px` }}
        >
          <div className={styles.dots} />
          <span className={styles.indicatorLabel}>
            {first == null && last == null
              ? `Level not specified`
              : `${first}-${last}`}
          </span>
        </div>
      )}
      <svg
        ref={containerRef}
        width="78"
        height="430"
        viewBox="0 0 78 430"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55.2706 8.04788C51.8419 7.46908 50.706 10.9419 47.13 10.063C46.8565 8.28368 45.2158 7.1904 43.7854 6.91172C40.4198 6.29005 39.8098 11.0919 37.559 12.3138C34.1934 14.136 35.3293 15.7223 37.6011 14.8005C40.6301 13.5572 41.8712 12.4853 43.2385 12.6568C43.3016 13.3214 43.3647 13.9645 43.4278 14.5861C43.4488 14.9077 43.7223 15.1649 44.0378 15.1864C47.4876 15.3364 51.4212 15.7652 55.6913 16.5369C56.6379 16.7084 57.5003 15.9152 57.4162 14.9291C57.3321 13.9859 57.2479 13.0427 57.1217 12.0995C57.0586 11.4349 57.0376 8.34799 55.2706 8.04788Z"
          fill="currentColor"
          ref={startElementRef}
          className={classNames(
            { first: firstIndex === 0, last: lastIndex === 0 },
            firstIndex <= 0 && lastIndex >= 0 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M56.1962 19.1735C53.5248 18.659 50.7902 18.2303 48.0977 17.9516C48.0556 16.2581 46.7094 14.929 45.3842 14.4145C42.1868 13.1497 40.6933 17.7372 38.2533 18.5304C34.6142 19.688 35.4345 21.4458 37.8536 20.9742C41.1351 20.3311 42.5444 19.495 43.8696 19.9667C43.9117 20.6741 43.9538 21.3601 43.9959 22.0246C44.0169 22.4962 44.3745 22.8607 44.8373 22.8821C48.0556 23.075 51.6737 23.4823 55.5652 24.1897C56.7011 24.4041 57.7739 23.5038 57.7528 22.3247C57.7528 21.8746 57.7529 21.4458 57.7318 21.0171C57.7108 20.0953 57.0587 19.345 56.1962 19.1735Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 1, last: lastIndex === 1 },
            firstIndex <= 1 && lastIndex >= 1 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M56.4277 26.8907C53.7563 26.3548 51.0007 25.926 48.2871 25.6259C48.224 24.1896 47.2354 22.9463 46.1626 22.3032C43.2177 20.5025 40.9879 24.7899 38.4637 25.1328C34.6984 25.6473 35.2033 27.5338 37.6644 27.4695C41.2614 27.3837 42.7549 26.7192 44.038 27.7053C44.017 28.0911 44.017 28.4984 43.996 28.8843C43.9539 29.656 44.5008 30.3206 45.2581 30.4278C48.1399 30.7922 51.2741 31.3281 54.5977 32.1213C56.0281 32.4643 57.4164 31.3924 57.5005 29.8919C57.5426 29.3988 57.5636 28.9058 57.5847 28.3913C57.6267 27.6838 57.1219 27.0193 56.4277 26.8907Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 2, last: lastIndex === 2 },
            firstIndex <= 2 && lastIndex >= 2 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M54.8081 34.7374C52.5994 34.2014 50.3486 33.7298 48.0768 33.344C48.182 31.8219 47.2144 30.4071 46.1416 29.6783C43.2808 27.7489 40.8618 31.8863 38.3165 32.1006C34.5302 32.4222 34.9509 34.3301 37.391 34.3944C40.5883 34.4801 42.1449 34.03 43.3229 34.6731C43.2808 34.8017 43.2387 34.9303 43.1967 35.0804C43.1756 35.1875 43.1546 35.3162 43.1336 35.4234C42.8391 36.8382 43.7436 38.2316 45.1319 38.4888C47.7192 38.9819 50.5169 39.6464 53.4408 40.5039C54.9133 40.9327 56.4278 39.9251 56.6381 38.3817C56.6802 38.103 56.7012 37.8457 56.7433 37.567C56.8906 36.2594 56.0702 35.0375 54.8081 34.7374Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 3, last: lastIndex === 3 },
            firstIndex <= 3 && lastIndex >= 3 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M53.6722 43.1611C51.4214 42.4966 49.1075 41.8749 46.7726 41.3604C46.7306 39.8384 45.8471 38.4236 44.8374 37.609C41.7242 35.0794 38.5479 39.6884 35.5819 39.6884C31.1856 39.7098 31.4801 41.9607 34.3198 42.2608C37.9379 42.6467 39.789 42.3037 41.0721 43.0968C40.9249 43.5256 40.7776 43.9757 40.6304 44.4259C40.3359 45.2834 40.8618 46.2052 41.7452 46.3553C44.9215 46.9126 48.4134 47.7272 52.1156 48.8419C53.4828 49.2492 54.8922 48.3918 55.1867 46.9769C55.2498 46.6554 55.3129 46.3338 55.376 46.0337C55.6074 44.7689 54.8712 43.5256 53.6722 43.1611Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 4, last: lastIndex === 4 },
            firstIndex <= 4 && lastIndex >= 4 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M51.6947 51.3074C49.3387 50.5999 46.8986 49.9568 44.4586 49.4209C44.2482 48.0275 43.3647 46.7627 42.3761 45.991C38.9684 43.3543 35.4766 48.1561 32.2161 48.1561C27.378 48.1776 27.7146 50.5356 30.8278 50.8572C34.551 51.2431 36.5704 50.9429 37.9587 51.5861C37.7694 52.0577 37.5801 52.5293 37.3908 53.0223C36.9911 54.0299 37.6221 55.1875 38.6739 55.359C42.0606 55.9163 45.8259 56.7738 49.8436 57.9528C51.2319 58.3601 52.6833 57.5241 53.0619 56.0878C53.2092 55.5519 53.3354 55.016 53.4616 54.48C53.8192 53.1081 53.0409 51.7147 51.6947 51.3074Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 5, last: lastIndex === 5 },
            firstIndex <= 5 && lastIndex >= 5 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M48.7288 60.2251C46.5411 59.582 44.2903 59.0032 42.0185 58.5101C41.7451 57.1167 40.8195 55.852 39.7888 55.0588C36.0866 52.272 32.2793 57.3526 28.7454 57.3526C23.4866 57.374 23.8442 59.8607 27.2308 60.2037C30.6806 60.5467 32.7841 60.3752 34.2776 60.7396C34.0252 61.3613 33.7728 61.9829 33.4993 62.6046C32.9103 64.0409 33.7938 65.6701 35.3083 65.9273C38.6529 66.4847 42.3551 67.3207 46.3308 68.4998C47.8874 68.9714 49.5281 68.071 50.0329 66.4847C50.2854 65.6915 50.5378 64.8769 50.7902 64.0838C51.2951 62.4331 50.3695 60.6967 48.7288 60.2251Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 6, last: lastIndex === 6 },
            firstIndex <= 6 && lastIndex >= 6 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M45.216 70.76C42.4604 69.9668 39.6206 69.238 36.7809 68.7021C36.5495 67.2443 35.5819 65.8724 34.5301 64.9935C31.2276 62.2281 25.8847 65.7009 22.6873 67.2443C18.312 69.3237 18.7537 72.3678 22.7504 70.5885C26.7261 68.8307 28.6192 69.581 30.0496 70.9101C29.8393 71.4031 29.6289 71.9176 29.4186 72.4321C28.7875 73.9327 29.5868 75.6691 31.1434 76.1192C34.3829 77.041 38.0219 78.3273 41.8924 80.0208C43.428 80.6853 45.216 79.935 45.826 78.3487L45.8891 78.1772C46.3308 77.0196 46.7726 75.862 47.1722 74.7258C47.8033 73.0538 46.8777 71.2316 45.216 70.76Z"
          fill="currentColor"
          id={lastIndex === 7 ? 'last' : ''}
          className={classNames(
            { first: firstIndex === 7, last: lastIndex === 7 },
            firstIndex <= 7 && lastIndex >= 7 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M41.0719 83C38.5897 81.9067 36.0234 80.8777 33.4782 80.0203C33.4992 78.1124 32.3002 76.3117 30.975 75.1969C27.378 72.11 21.5723 75.9901 18.0805 77.6836C13.3265 79.9988 13.8103 83.3644 18.1646 81.3922C22.1823 79.5915 24.2438 80.1703 25.7583 81.4351C25.5059 82.0996 25.2324 82.7642 24.98 83.4287C24.3489 85.0794 25.2114 86.923 26.8732 87.4375C30.1336 88.445 33.7516 89.7955 37.6011 91.5534C39.1787 92.2822 41.0298 91.4676 41.6188 89.7955C41.9764 88.788 42.334 87.8019 42.6706 86.8158C43.1964 85.3152 42.5023 83.6431 41.0719 83Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 8, last: lastIndex === 8 },
            firstIndex <= 8 && lastIndex >= 8 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M36.8649 94.6192C34.6352 93.5902 32.3424 92.6255 30.0285 91.7466C30.302 89.0241 28.1985 86.7947 26.1791 85.7443C21.7196 83.4076 16.6291 89.0241 13.4318 91.7466C9.07752 95.4552 10.3817 98.8851 14.4204 95.6696C17.5547 93.1615 19.6792 92.8399 21.3831 93.3758C20.9413 94.6621 20.5206 95.9483 20.0999 97.2559C19.511 99.0995 20.5627 101.072 22.4138 101.565C25.6111 102.422 29.1451 103.601 32.9314 105.166C34.7614 105.916 36.8018 104.909 37.3698 103.001C37.7905 101.565 38.2322 100.128 38.674 98.7351C39.1367 97.0844 38.3795 95.3266 36.8649 94.6192Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 9, last: lastIndex === 9 },
            firstIndex <= 9 && lastIndex >= 9 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M32.9104 108.511C30.4704 107.482 27.9461 106.496 25.4009 105.659C25.2116 102.873 23.087 100.729 21.0676 99.6786C16.2296 97.1704 11.0128 103.859 7.68928 107.117C3.16673 111.555 4.70229 115.478 8.86725 111.598C12.4222 108.318 14.7571 108.039 16.6923 108.811C16.3558 110.076 16.0402 111.34 15.7457 112.605C15.304 114.406 16.4399 116.228 18.2279 116.592C21.6146 117.3 25.4009 118.307 29.4396 119.701C31.2697 120.344 33.226 119.208 33.6467 117.278C34.0043 115.606 34.3829 113.934 34.7826 112.305C35.1822 110.783 34.3829 109.154 32.9104 108.511Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 10, last: lastIndex === 10 },
            firstIndex <= 10 && lastIndex >= 10 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M29.8602 123.195C27.1677 122.209 24.349 121.33 21.5303 120.58C22.0141 116.614 18.922 113.999 16.2295 113.077C11.0127 111.276 6.74261 118.822 3.90286 122.595C0.0113596 127.761 2.11488 131.47 5.75396 126.925C9.01441 122.852 11.4124 122.402 13.579 123.045C13.2635 124.76 12.9901 126.475 12.7166 128.212C12.4431 130.077 13.7894 131.77 15.6194 131.877C19.1954 132.113 23.2342 132.606 27.5884 133.485C29.3764 133.849 31.0803 132.585 31.3117 130.763C31.501 129.412 31.6903 128.04 31.9007 126.711C32.132 125.189 31.2906 123.71 29.8602 123.195Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 11, last: lastIndex === 11 },
            firstIndex <= 11 && lastIndex >= 11 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M27.9458 136.743C25.3584 136.207 22.6659 135.736 19.9945 135.393C19.3424 132.413 16.6078 130.612 14.2519 130.012C8.90894 128.64 5.24882 136.508 2.68253 140.495C-0.788271 145.961 1.5887 149.477 4.87019 144.675C7.37337 140.988 9.43481 139.98 11.37 140.045C11.2438 141.631 11.1387 143.239 11.0545 144.868C10.9704 146.69 12.4218 148.191 14.2098 148.105C17.8909 147.955 22.119 148.041 26.7678 148.512C28.5137 148.684 30.0493 147.312 30.1124 145.511C30.1755 143.646 30.2596 141.802 30.3858 139.98C30.491 138.437 29.4603 137.043 27.9458 136.743Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 12, last: lastIndex === 12 },
            firstIndex <= 12 && lastIndex >= 12 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M30.1758 154.943C30.1127 153.249 28.8295 151.877 27.1677 151.684C24.7697 151.427 22.3086 151.234 19.8686 151.148C18.964 148.361 16.2295 146.797 13.9156 146.346C8.50957 145.296 5.29119 153.356 2.97732 157.494C-0.177948 163.153 2.38834 166.519 5.37533 161.545C7.39471 158.18 9.18269 156.893 10.9076 156.615C10.9707 159.552 11.1179 162.553 11.3283 165.575C11.4755 167.655 13.4108 169.07 15.4091 168.619C17.3864 168.169 19.3216 167.805 21.0255 167.526C23.1921 167.183 25.4639 166.883 27.7567 166.647C29.6078 166.454 30.933 164.761 30.7437 162.874C30.4703 160.216 30.281 157.558 30.1758 154.943Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 13, last: lastIndex === 13 },
            firstIndex <= 13 && lastIndex >= 13 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M32.0899 172.2C31.7954 170.592 30.365 169.499 28.7663 169.649C26.0738 169.906 23.2551 170.271 20.4995 170.742C19.2584 168.663 16.9025 167.527 14.8831 167.205C9.43501 166.348 6.49009 174.494 4.32347 178.717C1.35751 184.483 4.05001 187.763 6.86872 182.683C8.90913 178.974 10.8023 177.709 12.6324 177.495C12.99 180.046 13.4317 182.64 13.9155 185.255C14.2521 187.077 16.1032 188.214 17.8491 187.656C21.2778 186.584 24.8748 185.834 27.8408 185.32C29.0187 185.127 30.2177 184.934 31.4378 184.762C33.2889 184.505 34.4668 182.661 34.0041 180.839C33.2468 177.924 32.6158 175.051 32.0899 172.2Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 14, last: lastIndex === 14 },
            firstIndex <= 14 && lastIndex >= 14 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M36.7808 190.078C36.2128 188.449 34.6352 187.441 32.9524 187.656C29.6919 188.085 26.3473 188.642 23.1921 189.371C21.6775 187.12 18.8588 186.262 16.6291 186.305C11.1179 186.412 9.60337 194.966 8.17298 199.51C6.21672 205.706 9.43509 208.471 11.3493 202.962C12.8218 198.739 14.5466 197.195 16.4188 196.766C17.155 199.746 17.9964 202.769 18.9009 205.856C19.532 207.957 21.8248 209.028 23.8021 208.128C27.5464 206.435 32.1741 204.784 37.5591 203.412C39.7047 202.855 40.8616 200.475 39.9781 198.396C38.8002 195.587 37.7274 192.822 36.7808 190.078Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 15, last: lastIndex === 15 },
            firstIndex <= 15 && lastIndex >= 15 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M44.5639 208.621C43.6383 206.649 41.4717 205.598 39.3682 206.091C35.2874 207.078 31.3117 208.321 27.4833 209.864C25.5481 207.742 22.7504 206.499 20.8152 206.52C15.304 206.627 13.7894 215.181 12.3591 219.725C10.4028 225.921 15.6405 227.85 16.9658 222.169C18.2699 216.596 20.1 216.424 22.477 216.767C23.8442 220.604 25.3167 224.506 26.9574 228.493C27.273 229.243 28.1144 229.608 28.8716 229.286C33.3942 227.378 38.632 225.814 44.8794 224.099C47.3615 223.413 47.9716 221.162 47.614 218.246C47.2143 215.052 45.9943 211.665 44.5639 208.621Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 16, last: lastIndex === 16 },
            firstIndex <= 16 && lastIndex >= 16 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M52.0943 229.179C51.295 227.142 49.2125 225.942 47.088 226.285C42.9441 226.971 38.695 227.914 34.7614 229.179C32.9734 226.907 30.2809 225.47 28.3457 225.363C22.8345 225.085 20.731 233.509 18.985 237.925C16.6081 243.971 21.6986 246.264 23.4235 240.691C25.1063 235.224 26.9363 235.181 29.2923 235.696C30.3861 239.619 31.5851 243.628 32.9524 247.701C33.2048 248.472 34.0252 248.901 34.8035 248.644C39.4522 247.057 46.0783 245.342 52.4309 244.078C54.9551 243.585 56.4276 240.905 55.5651 238.44C54.4713 235.396 53.3144 232.309 52.0943 229.179Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 17, last: lastIndex === 17 },
            firstIndex <= 17 && lastIndex >= 17 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M58.8887 248.601C58.2366 246.586 56.2172 245.364 54.1768 245.771C49.2756 246.736 44.0379 248.022 39.6836 249.309C38.6739 247.444 36.7176 246.586 34.7403 246.329C31.5009 245.921 30.7016 248.237 28.3667 250.659C26.768 252.331 24.5593 253.767 23.8021 255.547C21.8458 260.284 27.2518 263.028 28.556 261.656C30.7437 259.341 32.4475 255.718 35.75 257.133C36.2549 258.848 36.7387 260.541 37.2015 262.214C37.8115 264.443 40.0622 265.751 42.2499 265.15C46.8987 263.864 52.5992 262.621 57.9632 261.721C60.4453 261.313 61.9809 258.784 61.2657 256.34C60.5294 253.81 59.7301 251.238 58.8887 248.601Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 18, last: lastIndex === 18 },
            firstIndex <= 18 && lastIndex >= 18 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M64.1685 266.136C63.4743 264.143 61.4339 262.964 59.4145 263.392C54.5344 264.443 49.3177 265.836 44.9844 267.208C43.9537 265.365 41.9764 264.55 39.9781 264.336C36.7386 263.993 35.9603 266.329 33.6885 268.773C32.1109 270.467 29.9233 271.946 29.2291 273.746C27.357 278.527 32.8051 281.164 34.0882 279.77C36.2338 277.412 37.8745 273.747 41.1981 275.097C41.745 276.812 42.2499 278.484 42.7337 280.156C43.3858 282.364 45.6576 283.629 47.8452 282.986C52.4519 281.614 58.1524 280.263 63.4954 279.256C65.9565 278.806 67.45 276.233 66.6927 273.811C65.9144 271.303 65.052 268.73 64.1685 266.136Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 19, last: lastIndex === 19 },
            firstIndex <= 19 && lastIndex >= 19 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M70.1845 285.902C69.5114 283.351 67.0503 281.743 64.5051 282.214C60.0456 283.029 55.3127 284.122 51.1688 285.237C50.3274 284.144 49.0653 283.372 47.7611 282.836C41.8502 280.414 41.5136 284.422 38.6108 286.588C37.0121 287.788 34.4038 288.646 33.4361 290.017C30.344 294.455 36.0445 298.871 37.7694 298.142C40.3357 297.092 41.5978 292.654 46.5621 295.184C46.8566 296.556 47.1511 297.928 47.4035 299.278C47.9504 302.044 50.6008 303.844 53.2933 303.244C58.1945 302.172 64.0002 301.165 69.3011 300.564C71.5308 300.307 73.0664 298.142 72.5615 295.913C71.8884 292.676 71.0891 289.331 70.1845 285.902Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 20, last: lastIndex === 20 },
            firstIndex <= 20 && lastIndex >= 20 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M74.7072 307.081C74.3496 304.873 72.3723 303.329 70.2057 303.586C66.4614 303.994 62.4226 304.615 58.6153 305.344C57.9632 304.23 56.9745 303.351 55.8597 302.729C46.1625 297.391 46.2256 304.508 42.2499 306.137C40.0412 307.038 37.0963 306.63 35.5607 307.96C30.6385 312.204 34.9087 319.536 38.5267 319.385C41.6609 319.235 45.3631 312.976 49.9278 316.384C50.054 317.713 50.1591 319.021 50.2643 320.286C50.4326 322.644 52.473 324.38 54.7658 324.166C59.7512 323.673 66.4824 323.458 72.7509 323.63C74.8334 323.694 76.5162 321.872 76.3059 319.75C76.1586 317.842 75.0858 309.417 74.7072 307.081Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 21, last: lastIndex === 21 },
            firstIndex <= 21 && lastIndex >= 21 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M73.6554 326.718C68.8594 326.568 63.6848 326.632 59.2463 326.889C58.8046 326.289 58.3208 325.753 57.837 325.324C49.6543 317.8 48.9812 325.11 44.7321 325.753C42.3761 326.096 39.6837 324.638 37.8746 325.56C32.09 328.497 35.708 338.229 37.8536 338.765C42.0396 339.837 46.8146 336.343 50.4957 336.772C50.4116 338.551 50.2854 340.266 50.1381 341.895C49.9698 343.739 51.2109 345.389 52.9989 345.689C58.9939 346.654 66.5245 348.24 73.424 350.02C74.8334 350.384 76.2217 349.398 76.3689 347.94C76.6634 344.961 77.021 333.042 76.9369 330.126C76.9159 328.283 75.4644 326.782 73.6554 326.718Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 22, last: lastIndex === 22 },
            firstIndex <= 22 && lastIndex >= 22 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M72.4353 352.934C68.1021 351.82 63.5164 350.791 59.2673 349.933C59.078 349.44 58.8887 348.904 58.6573 348.347C54.892 338.893 50.706 345.303 46.3097 344.595C43.8906 344.209 40.1464 341.594 38.0849 341.937C31.5009 343.009 31.7954 353.535 35.14 355.571C38.3794 357.565 45.0476 352.784 47.1511 358.808C47.1931 360.073 47.8663 361.273 49.0232 361.895C54.2399 364.746 60.4874 368.519 66.1038 372.271C67.9128 373.471 70.3739 372.785 71.2784 370.791C71.5939 370.084 71.8884 369.334 72.1619 368.54C72.6457 367.083 74.623 359.537 75.0647 357.157C75.4434 355.271 74.2864 353.406 72.4353 352.934Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 23, last: lastIndex === 23 },
            firstIndex <= 23 && lastIndex >= 23 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.379 380.393C62.0915 380.114 62.8233 379.775 63.5586 379.367C65.4518 378.316 65.557 375.572 63.7479 374.372C57.9212 370.535 51.4845 366.697 46.3519 363.932C45.7629 363.61 45.0267 363.846 44.7111 364.446C44.3019 365.223 43.8715 366.03 43.4287 366.857C42.2321 365.57 41.057 364.151 39.936 362.796L39.8378 362.677C37.8126 360.234 35.9019 357.929 34.8876 357.887H34.8875C34.7823 357.887 34.6982 357.887 34.6141 357.93C33.4196 358.528 33.436 359.977 33.4569 361.831L33.4572 361.853C33.4782 363.332 33.4782 365.025 32.8471 366.633C32.0899 368.584 30.933 368.841 29.9022 369.055L29.8779 369.061C28.9191 369.273 28.0036 369.476 27.357 371.028C26.7236 372.548 26.6459 373.623 26.5648 374.744C26.5624 374.777 26.5601 374.81 26.5576 374.843L26.5568 374.857C26.4729 376.182 26.3862 377.551 25.4428 379.645C24.4674 381.76 23.1849 382.06 21.9184 382.356C21.9012 382.36 21.884 382.364 21.8668 382.368C20.7519 382.604 19.595 382.861 18.5432 384.383C17.2468 386.279 17.3846 387.964 17.5309 389.753L17.5336 389.785C17.5368 389.825 17.54 389.865 17.5432 389.905C17.6459 391.175 17.7521 392.489 17.3442 393.944C16.8872 395.638 15.8144 396.641 14.795 397.595L14.7569 397.631L14.7418 397.645C13.5696 398.733 12.4841 399.74 12.2537 401.725C12.0019 403.907 12.6504 405.427 13.2801 406.903L13.2845 406.913C13.7052 407.899 14.1679 409.121 14.2941 410.514C14.3152 410.772 14.5045 410.965 14.7569 411.008C16.5932 411.254 18.0037 411.619 19.3596 411.971C19.4171 411.985 19.4746 412 19.5319 412.015C19.7423 412.079 19.9526 411.951 20.0157 411.715C20.1209 411.265 20.2471 410.793 20.3523 410.472C22.2454 404.576 25.6742 398.767 29.755 394.48C34.9086 389.099 41.0088 386.226 47.4035 386.205C48.6235 386.205 49.7804 386.248 50.9163 386.312C55.3337 386.527 58.8256 386.677 60.8029 382.861C61.2593 382.073 61.4483 381.245 61.379 380.393Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 24, last: lastIndex === 24 },
            firstIndex <= 24 && lastIndex >= 24 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
        <path
          d="M18.9851 427.643C18.1437 428.222 13.9998 421.533 13.3687 416.731C13.0952 414.588 13.558 413.151 13.8946 412.058C13.9998 411.737 14.3153 411.522 14.6518 411.587C16.1874 411.844 18.4592 412.358 19.511 412.616C19.8265 412.701 20.0368 413.001 19.9737 413.344C19.0061 419.69 20.9414 426.314 18.9851 427.643Z"
          fill="currentColor"
          className={classNames(
            { first: firstIndex === 25, last: lastIndex === 25 },
            firstIndex <= 25 && lastIndex >= 25 ? styles.selected : '',
          )}
          stroke="#A3ADB5"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export default Spine;
