import styles from './DropDown.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import React from 'react';
import classNames from 'classnames';

import {
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
} from 'reakit/Form';

const DropDown = ({ popover, items, form, name, onClickEvent }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <FormRadioGroup
      onChange={() => {
        if (!isDesktop) {
          popover.hide();
        }
      }}
      className={classNames(styles.dropdownContainer, {
        [styles.dropdownHasItems]: items.length > 0,
      })}
      {...form}
      name={name}
    >
      {items.map(({ label, value, key }) => (
        <label
          key={key ?? `${name}${value}`}
          className={classNames(styles.dropdownLabel, {
            [styles.activeDropdownItem]: form.values[name] === value,
          })}
        >
          <FormRadio
            {...form}
            name={name}
            value={value}
            onClick={onClickEvent ? () => onClickEvent(value, name) : undefined}
          />
          {label}
        </label>
      ))}
    </FormRadioGroup>
  );
};

export default DropDown;
