import styles from './InjurySeverity.module.scss';
import DropDown from 'components/SearchBar/DropDown/DropDown';
import DataView from 'components/SearchBar/DataView/DataView';
import React, { useEffect, useState } from 'react';
import { unstable_Form as ReaKitForm } from 'reakit/Form';
import { Popover } from 'reakit/Popover';
import PropTypes from 'prop-types';

const injurySeverity = [
  {
    id: 0,
    label: 'Any',
    value: 'any',
  },
  {
    id: 1,
    label: 'Complete',
    value: 'complete',
  },
  {
    id: 2,
    label: 'Incomplete',
    value: 'incomplete',
  },
  {
    id: 3,
    label: 'AIS-A',
    value: 'ais_a',
  },
  {
    id: 4,
    label: 'AIS-B',
    value: 'ais_b',
  },
  {
    id: 5,
    label: 'AIS-C',
    value: 'ais_c',
  },
  {
    id: 6,
    label: 'AIS-D',
    value: 'ais_d',
  },
];

const InjurySeverity = ({ popover, form, onClickEvent, hideOptionAny }) => {
  const [injurySeverityValues, setInjurySeverityValues] = useState(
    injurySeverity,
  );

  useEffect(() => {
    if (hideOptionAny) {
      setInjurySeverityValues(injurySeverity.filter(el => el.value !== 'any'));
    }
  }, []);

  return (
    <DataView
      popover={popover}
      name="Severity"
      className={styles.dataView}
      noSpacing
    >
      <DropDown
        popover={popover}
        name="severity"
        items={injurySeverityValues}
        form={form}
        onClickEvent={onClickEvent}
      />
    </DataView>
  );
};

export default InjurySeverity;

InjurySeverity.propTypes = {
  form: PropTypes.objectOf(ReaKitForm).isRequired,
  popover: PropTypes.objectOf(Popover).isRequired,
  hideOptionAny: PropTypes.bool,
};

InjurySeverity.defaultProps = {
  hideOptionAny: false,
};
