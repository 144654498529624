// disables false eslint false positive as alt tags must be based by prop
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import styles from '@moonshineragency/ui/src/components/Image/Image.module.scss';
import classNames from 'classnames';

const Image = ({ srcSet, src, className, ...rest }) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const handleChange = event => {
    if (event.intersectionRatio > 0) {
      setIntersecting(true);
    }
  };
  return (
    <Observer onChange={handleChange} rootMargin="33% 0% 33%">
      <img
        className={classNames(className, {
          [styles.hidden]: !isIntersecting,
        })}
        src={!isIntersecting ? null : src}
        srcSet={!isIntersecting ? null : srcSet}
        {...rest}
      />
    </Observer>
  );
};

export { Image as default };
