import Container from 'components/Container/Container';
import styles from 'components/Navigation/Navigation.module.scss';
import SearchBarWrapper from 'components/SearchBar/SearchBarWrapper';
import useMediaQuery from 'hooks/useMediaQuery';
import { IconButton } from '@moonshineragency/ui/src/components/Button/Button';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { Link } from '@moonshineragency/ui/src/components/Link/Link';
import React, { useState, useEffect } from 'react';
import { Button } from 'reakit/Button';
import {
  Dialog,
  DialogBackdrop,
  useDialogState,
  DialogDisclosure,
} from 'reakit/Dialog';
import logo from 'site/src/images/fullLogo.svg';
import classNames from 'classnames';
import Content from '@moonshineragency/ui/src/components/Content/Content';

const SearchDialogBackDrop = ({ dialog }) => {
  return (
    <DialogBackdrop {...dialog} className={styles.searchOverlay}>
      <Dialog {...dialog} aria-label="Welcome">
        <div className={styles.searchExtended}>
          <SearchBarWrapper
            disclosure={dialog}
            variant="expanded"
            isExpandable
          />
        </div>
      </Dialog>
    </DialogBackdrop>
  );
};

const NavigationSearch = ({ dialog, reduced }) => {
  return (
    <div className={styles.search}>
      {!dialog.visible && !reduced && (
        <SearchBarWrapper disclosure={dialog} variant="view" isExpandable />
      )}

      <DialogDisclosure
        {...dialog}
        className={classNames(styles.initialSearchWrapper, {
          [styles.hide]: dialog.visible || !reduced,
        })}
      >
        <Content
          size="uiText75"
          theme="particular"
          className={styles.initialSearch}
        >
          Start new search
        </Content>

        <Button
          size="small"
          theme="primary"
          className={styles.submit}
          shape="bubble"
        >
          <div className={styles.initialIconWrapper}>
            <IconWrapper
              Icon={IconComponents.SearchIconSmall}
              className={styles.icon}
            />
          </div>
        </Button>
      </DialogDisclosure>
      <SearchDialogBackDrop dialog={dialog} />
    </div>
  );
};

const NavigationSearchIcon = ({ dialog }) => {
  return (
    <div className={styles.search}>
      <DialogDisclosure {...dialog} className={styles.iconWrapper}>
        <Button
          size="small"
          theme="primary"
          className={styles.submit}
          shape="bubble"
        >
          <IconWrapper
            Icon={IconComponents.SearchIconSmall}
            className={styles.icon}
          />
        </Button>
      </DialogDisclosure>
      <SearchDialogBackDrop dialog={dialog} />
    </div>
  );
};

const Navigation = ({ isWide, hideSearch, onlyShowSearchIcon, reduced }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [isOpen, setIsOpen] = useState(false);
  const dialog = useDialogState({ modal: false });
  const dialogReduced = useDialogState({ modal: false });

  useEffect(() => {
    if (dialog.visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [dialog.visible]);

  const menuLinks = [
    { to: '/trials', content: 'All Trials', id: 'trials' },
    { to: '/frequently-asked-questions', content: 'FAQ', id: 'FAQ' },
    { to: '/about', content: 'About', id: 'about' },
  ];

  return (
    <div className={styles.container}>
      <div>
        <Container
          size={isWide && 'wide'}
          className={classNames(styles.wrapper, {
            [styles.searchVisible]: dialog.visible,
          })}
        >
          <Link
            asType="internal"
            to="/"
            className={styles.logoWrapper}
            variant="noUnderline"
          >
            <img
              alt="SCI logo"
              height="40"
              src={logo}
              className={styles.logo}
            />
          </Link>
          {/* NavigationSearch will be also displayed on mobile trials page */}
          {isDesktop && !hideSearch && (
            <NavigationSearch dialog={dialogReduced} reduced={reduced} />
          )}

          {!isDesktop && !reduced && !hideSearch && (
            <NavigationSearch dialog={dialog} />
          )}

          <div className={styles.mobileMenu}>
            {onlyShowSearchIcon && <NavigationSearchIcon dialog={dialog} />}
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              as="div"
              aria-label={isOpen ? 'Close menu' : 'Open menu'}
            >
              <IconWrapper
                Icon={
                  isOpen
                    ? IconComponents.CrossXIcon
                    : IconComponents.NavigationIcon
                }
              />
            </IconButton>
          </div>
          {isOpen ? (
            <ul className={styles.dropMenu}>
              {menuLinks.map(link => {
                return (
                  <li className={styles.menuItem} key={link.id}>
                    <Link
                      asType="internal"
                      type="navigation"
                      variant="noUnderline"
                      className={styles.item}
                      to={link.to}
                    >
                      <span className={styles.itemInner}>{link.content}</span>
                    </Link>
                  </li>
                );
              })}
              <Button
                onClick={() => setIsOpen(!isOpen)}
                className={styles.overlay}
              />
            </ul>
          ) : (
            <div className={styles.links}>
              {menuLinks.map(link => {
                return (
                  <Link
                    asType="internal"
                    type="navigation"
                    variant="noUnderline"
                    activeClassName={styles.active}
                    className={styles.item}
                    to={link.to}
                    key={link.id}
                  >
                    <span className={styles.itemInner}>{link.content}</span>
                  </Link>
                );
              })}
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default React.memo(Navigation);
