import React from 'react';
import classNames from 'classnames';
import styles from '@moonshineragency/ui/src/components/Link/Link.module.scss';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

const getLinkElement = type => {
  switch (type) {
    case 'internal':
      return { as: GatsbyLink };
    case 'external':
      return { as: 'a' };
    default:
      return { as: GatsbyLink };
  }
};

export const Link = React.forwardRef(
  (
    {
      to,
      asType,
      as,
      type,
      children,
      variant,
      size,
      className,
      theme,
      ...other
    },
    ref,
  ) => {
    const Element = as ? { as } : getLinkElement(asType);
    return (
      <Element.as
        ref={ref}
        href={to}
        to={to}
        className={classNames(
          styles[`${type}Link${size ? `Size${size}` : ''}`],
          className,
          styles[variant],
          styles[theme],
        )}
        {...other}
      >
        {children}
      </Element.as>
    );
  },
);

Link.propTypes = {
  asType: PropTypes.string,
  size: PropTypes.oneOf(['50', '75', '100']),
  type: PropTypes.oneOf(['text', 'navigation', 'ui']),
  theme: PropTypes.oneOf(['primary', 'default', 'contrast', 'link', 'regular']),
  variant: PropTypes.oneOf(['underline', 'noUnderline']),
};

Link.defaultProps = {
  asType: 'internal',
  type: 'text',
  size: null,
  theme: 'default',
  variant: 'underline',
};

export { Link as default };
