import styles from '@moonshineragency/ui/src/components/Card/Card.module.scss';

import React from 'react';
import classNames from 'classnames';

const Card = ({
  theme = 'default',
  children,
  className,
  as = 'div',
  variant = 'elevated',
  shape,
  noSpacing,
  ...other
}) => {
  const Element = { as };
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <Element.as
      className={classNames(
        styles[theme],
        styles[variant],
        styles[shape],
        styles.container,
        className,
        {
          [styles.noSpacing]: noSpacing,
        },
      )}
      {...other}
    >
      {children}
    </Element.as>
  );
};
export { Card as default };
