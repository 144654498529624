import styles from './InjuryLevel.module.scss';
import DataView from 'components/SearchBar/DataView/DataView';
import Spine from 'components/Spine/Spine';
import React, { useState, useEffect } from 'react';
import { Button as BaseButton } from 'reakit/Button';
import classNames from 'classnames';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';

import { useTabState, Tab, TabList } from 'reakit/Tab';
import {
  unstable_FormGroup as FormGroup,
  unstable_FormCheckbox as FormCheckbox,
} from 'reakit/Form';

const InjuryLevel = ({ form, popover, hideAnyButton = false }) => {
  const [currentSelectionIndex, setCurrentHover] = useState(0);
  const [spineOrder, _setSpineOrder] = useState([]);
  const sectionItemsTab = useTabState();
  const itemsTab = useTabState();

  const setSpineOrder = spineSections => {
    let spineOrderData = [];

    spineSections.forEach(({ sections }) => {
      sections.forEach(section => {
        spineOrderData = [...spineOrderData, section];
      });
    });

    _setSpineOrder(spineOrderData);
  };

  const spineSections = [
    {
      id: 'cervical',
      name: 'Cervical',
      sections: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8'],
    },
    {
      id: 'thoracic',
      name: 'Thoracic',
      sections: [
        'T1',
        'T2',
        'T3',
        'T4',
        'T5',
        'T6',
        'T7',
        'T8',
        'T9',
        'T10',
        'T11',
        'T12',
      ],
    },
    { id: 'lumbar', name: 'Lumbar', sections: ['L1', 'L2', 'L3', 'L4', 'L5'] },
    { id: 'sacral', name: 'Sacral', sections: ['S1', 'S2', 'S3', 'S4', 'S5'] },
  ];

  useEffect(() => {
    setSpineOrder(spineSections);
  }, [form.values]);

  const areBothSelected = form?.values?.injuryLevel?.length > 1;
  const firstIndex = spineOrder.indexOf(form?.values?.injuryLevel?.[0]);
  const lastIndex = spineOrder.indexOf(form?.values?.injuryLevel?.[1]);

  const handleResetClick = () => {
    form.update('injuryLevel', []);
    // eslint-disable-next-line no-underscore-dangle
    const _paq = window._paq || [];
    _paq.push([
      'trackEvent',
      'Injury level Button',
      `Clicked on Any level`,
      'Trial injury level',
    ]);
  };

  const transformSelectedProperties = section => {
    if (form?.values?.injuryLevel?.length === 1) {
      return form?.values?.injuryLevel?.some(
        el => el.slice(0, 1) === section?.name?.slice(0, 1),
      );
    }

    const startIndex = spineOrder.findIndex(
      el => el === form?.values?.injuryLevel?.[0],
    );
    const endIndex = spineOrder.findIndex(
      el => el === form?.values?.injuryLevel?.[1],
    );
    const selectedProperties = spineOrder.slice(startIndex, endIndex);

    return selectedProperties?.some(
      el => el.slice(0, 1) === section?.name?.slice(0, 1),
    );
  };

  return (
    <DataView
      popover={popover}
      hasDone
      name={
        form?.values?.injuryLevel?.length === 0
          ? 'Any'
          : `${form?.values?.injuryLevel?.[0]} to ${
              form?.values?.injuryLevel?.[1] ?? ''
            }`
      }
    >
      <Heading size="h4" className={styles.infoText} noSpacing>
        Select your level OR range of injury
      </Heading>
      <div className={styles.spineContainer}>
        <div className={styles.leftContainer}>
          <FormGroup
            {...form}
            name="injuryLevel"
            onChange={ev => {
              if (form?.values?.injuryLevel?.length === 0) {
                return;
              }
              const firstval = spineOrder.indexOf(
                form?.values?.injuryLevel?.[0],
              );
              const currentVal = spineOrder.indexOf(ev.target.value);
              if (firstval <= currentVal) {
                return;
              }
              form.update('injuryLevel', [
                ev.target.value,
                form.values.injuryLevel[0],
              ]);
            }}
          >
            <TabList
              {...sectionItemsTab}
              className={styles.sectionGroup}
              role="none"
            >
              {spineSections.map(section => {
                return (
                  <div className={styles.section} key={section.id}>
                    <Tab
                      className={classNames(styles.spineSectionLabel, {
                        [styles.activeButton]: transformSelectedProperties(
                          section,
                        ),
                      })}
                      as="legend"
                      name="injuryLevel"
                      onClick={() => {
                        if (section.name === 'Cervical')
                          form.update('injuryLevel', ['C1', 'C8']);
                        else if (section.name === 'Thoracic')
                          form.update('injuryLevel', ['T1', 'T12']);
                        else if (section.name === 'Lumbar')
                          form.update('injuryLevel', ['L1', 'L5']);
                        else if (section.name === 'Sacral')
                          form.update('injuryLevel', ['S1', 'S5']);
                      }}
                      role="none"
                      {...sectionItemsTab}
                    >
                      {section.name}
                    </Tab>
                    <TabList
                      {...itemsTab}
                      role="none"
                      className={styles.spineSectionList}
                    >
                      {section.sections.map(spineSection => (
                        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                        <label
                          key={spineSection}
                          onMouseOver={() => {
                            setCurrentHover(spineSection);
                          }}
                          className={classNames(styles.spineLabel, {
                            [styles.spineLabelFirst]:
                              spineOrder.indexOf(spineSection) === firstIndex ||
                              (spineOrder.indexOf(spineSection) === lastIndex &&
                                areBothSelected === false),
                            [styles.spineLabelBackground]: !areBothSelected,
                            [styles.spineLabelLast]:
                              spineOrder.indexOf(spineSection) === lastIndex,
                            [styles.spineInbetween]:
                              spineOrder.indexOf(
                                form?.values?.injuryLevel?.[0],
                              ) < spineOrder.indexOf(spineSection) &&
                              spineOrder.indexOf(
                                form?.values?.injuryLevel?.[1] ||
                                  currentSelectionIndex,
                              ) >= spineOrder.indexOf(spineSection) &&
                              form?.values?.injuryLevel?.[0],
                          })}
                        >
                          <Tab
                            role="none"
                            as={FormCheckbox}
                            name="injuryLevel"
                            value={spineSection}
                            onKeyDown={e => {
                              if (
                                form?.values?.injuryLevel?.length >= 2 &&
                                e.keyCode === 32
                              ) {
                                form.update('injuryLevel', []);
                              }
                            }}
                            {...form}
                            {...itemsTab}
                          />
                          <span
                            className={styles.indicatorSpan}
                            onClick={() => {
                              if (form?.values?.injuryLevel?.length >= 2) {
                                form.update('injuryLevel', []);
                              }

                              // eslint-disable-next-line no-underscore-dangle
                              const _paq = window._paq || [];
                              _paq.push([
                                'trackEvent',
                                'Select Injury Level',
                                `Selected ${
                                  form?.values?.injuryLevel?.[0] || spineSection
                                } ${spineSection} `,
                                'Trial injury level',
                              ]);
                            }}
                            onKeyDown={() => {
                              // eslint-disable-next-line no-underscore-dangle
                              const _paq = window._paq || [];
                              _paq.push([
                                'trackEvent',
                                'Select Injury Level',
                                `Selected ${
                                  form.values.injuryLevel[0] || spineSection
                                } ${spineSection} `,
                                'Trial injury level',
                              ]);
                            }}
                            role="button"
                            tabIndex={-1}
                          >
                            {spineSection}
                          </span>
                        </label>
                      ))}
                    </TabList>
                  </div>
                );
              })}
            </TabList>
          </FormGroup>
        </div>
        <div className={styles.spineWrapper}>
          {!hideAnyButton && (
            <BaseButton
              as={Tab}
              role="none"
              onClick={handleResetClick}
              className={classNames(styles.anyButton, {
                [styles.activeButton]: form?.values?.injuryLevel?.length === 0,
              })}
              {...sectionItemsTab}
            >
              Any level
            </BaseButton>
          )}
          <Spine
            className={styles.spine}
            first={
              !form?.values?.injuryLevel ? 'C1' : form.values.injuryLevel[0]
            }
            last={
              !form?.values?.injuryLevel?.[1]
                ? form?.values?.injuryLevel?.[0]
                : form?.values?.injuryLevel?.[1]
            }
            isInteractive
          />
        </div>
      </div>
    </DataView>
  );
};

export default InjuryLevel;
