import styles from './AdminLogin.module.scss';
import Client from 'api/client';
import useStore from 'store';
import { AuthorizationContext } from 'context';
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  unstable_useFormState as useFormState,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';
import { navigate } from 'gatsby';

const AdminLogin = ({
  setIsPasswordForgotten,
  changeModalHeader,
  isDialogVisible,
}) => {
  const [, setPermissions] = useContext(AuthorizationContext);

  const store = useStore();
  useEffect(() => {
    changeModalHeader('Login');
  });

  const form = useFormState({
    values: {
      email: '',
      password: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.email) {
        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter email'),
        };
      }
      if (!values.password) {
        errors = {
          ...errors,
          password: renderInputErrorFor('Please enter password'),
        };
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async ({ email, password }) => {
      try {
        const {
          data: {
            data: { token, user, permissions },
          },
        } = await Client.admin.login(email, password);
        store.setUser({ email, token, user, permissions });
        setPermissions(permissions);
      } catch (e) {
        store.setUser(null);
        form.errors = { login: '' };
        throw form.errors;
      }
      navigate('/admin/trials/list');
    },
  });

  useEffect(() => {
    if (!isDialogVisible) {
      form.reset();
    }
  }, [isDialogVisible]);

  const submitArea = (
    <div className={styles.actionButtonsWrapper}>
      <Button
        theme="link"
        className={styles.actionTextLink}
        onClick={() => setIsPasswordForgotten(true)}
      >
        Forgot your password?
      </Button>

      <FormSubmitButton
        as={Button}
        theme="primary"
        size="default"
        {...form}
        className={styles.loginButton}
      >
        Login
      </FormSubmitButton>
    </div>
  );

  return (
    <Form hasGeneralFormErrorMessage submitArea={submitArea} form={form}>
      <InputField {...form} name="email" label="Your email address" noMessage />
      <FormMessage {...form} name="email" />
      <InputField
        {...form}
        className={styles.password}
        type="password"
        name="password"
        label="Your password"
        noMessage
      />
      <FormMessage {...form} name="password" />
    </Form>
  );
};

AdminLogin.propTypes = {
  setIsPasswordForgotten: PropTypes.func.isRequired,
  changeModalHeader: PropTypes.func.isRequired,
  isDialogVisible: PropTypes.bool.isRequired,
};

export default AdminLogin;
