import DropDown from 'components/SearchBar/DropDown/DropDown';
import DataView from 'components/SearchBar/DataView/DataView';
import React, { useEffect, useState } from 'react';
import { Popover } from 'reakit/Popover';
import PropTypes from 'prop-types';
import { unstable_Form as ReaKitForm } from 'reakit/Form';

const timeSinceInjury = [
  {
    id: 1,
    label: 'Any',
    value: 'any',
  },
  {
    id: 2,
    label: 'Under 48hrs',
    value: 'under-48h',
  },
  {
    id: 3,
    label: '48 hrs - 6 months',
    value: '48h-6mon',
  },
  {
    id: 4,
    label: 'over 6 months',
    value: 'over-6mon',
  },
];

const TimeSinceInjury = ({
  form,
  popover,
  onClickEvent,
  hideOptionAny = false,
}) => {
  const [timeSinceInjuryValues, setTimeSinceInjuryValues] = useState(
    timeSinceInjury,
  );

  useEffect(() => {
    if (hideOptionAny) {
      setTimeSinceInjuryValues(
        timeSinceInjury.filter(el => el.value !== 'any'),
      );
    }
  }, []);

  return (
    <DataView popover={popover} name="Time since injury" noSpacing>
      <DropDown
        popover={popover}
        name="time_since_injury"
        items={timeSinceInjuryValues}
        form={form}
        onClickEvent={onClickEvent}
      />
    </DataView>
  );
};

TimeSinceInjury.propTypes = {
  form: PropTypes.objectOf(ReaKitForm).isRequired,
  popover: PropTypes.objectOf(Popover).isRequired,
  hideOptionAny: PropTypes.bool,
};

TimeSinceInjury.defaultProps = {
  hideOptionAny: false,
};

export default TimeSinceInjury;
