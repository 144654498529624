import { AuthorizationContext } from 'context';
import { usePermissionsStore, useStore } from 'store';
import React, { useEffect } from 'react';

export const AuthorizationContextProvider = ({ children }) => {
  const permissionsStore = usePermissionsStore();
  const userStore = useStore();

  useEffect(() => {
    if (!userStore.user) {
      permissionsStore.setPermissions({});
    }
  }, [userStore]);

  return (
    <AuthorizationContext.Provider
      value={[permissionsStore.permissions, permissionsStore.setPermissions]}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
