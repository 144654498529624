import styles from './DataView.module.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import { Button as BaseButton } from 'reakit/Button';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import Button from '@moonshineragency/ui/src/components/Button/Button';

const DataView = ({
  value,
  children,
  noSpacing,
  popover,
  name,
  hasDone = false,
}) => {
  return (
    <Card className={styles.dataView} noSpacing={noSpacing}>
      <div className={styles.dataViewHeader}>
        <BaseButton
          aria-hidden="true"
          onClick={() => {
            popover.hide();
          }}
          className={styles.dataViewHeaderIcon}
        >
          <IconComponents.SmallArrowLongLeftIcon />
        </BaseButton>
        {value || name}
      </div>
      {children}
      {hasDone && (
        <div className={styles.dataViewActionWrapper}>
          <Button
            aria-hidden="true"
            isFullWidth
            onClick={() => {
              popover.hide();
            }}
          >
            Done
          </Button>
        </div>
      )}
    </Card>
  );
};

export default DataView;
