import styles from '@moonshineragency/ui/src/components/Content/Content.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Component used for all paragraphs etc.
 */
const Content = ({
  as,
  align,
  withParser,
  children,
  size,
  noSpacing,
  notResponsive,
  className,
  theme,
  variant,
  tabIndex,
  forwardedRef,
  ...props
}) => {
  const Element = { as };
  return (
    <Element.as
      className={classNames(
        styles.content,
        styles[size],
        styles[align],
        styles[variant],
        styles[theme],
        {
          [styles.notResponsive]: notResponsive,
          [styles.noSpacing]: noSpacing,
        },
        className,
      )}
      /* eslint-disable react/no-danger */
      dangerouslySetInnerHTML={
        withParser ? { __html: withParser && children } : null
      }
      tabIndex={tabIndex || undefined}
      ref={forwardedRef}
      {...props}
    >
      {!withParser ? children : null}
    </Element.as>
  );
};
Content.propTypes = {
  as: PropTypes.string,
  align: PropTypes.string,
  /** size of the content */
  size: PropTypes.oneOf([
    'copy75',
    'copy100',
    'uiText100',
    'uiText75',
    'uiText50',
  ]),
  children: PropTypes.node,
  withParser: PropTypes.bool,
  noSpacing: PropTypes.bool,
  notResponsive: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'bold', 'light']),
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'neutral',
    'contrast',
    'regular',
    'particular',
  ]),
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  forwardedRef: PropTypes.oneOfType([PropTypes.func]),
};

Content.defaultProps = {
  withParser: false,
  notResponsive: false,
  noSpacing: false,
  size: 'copy100',
  children: null,
  className: null,
  align: null,
  as: 'p',
  variant: 'default',
  theme: 'primary',
  tabIndex: null,
  forwardedRef: null,
};

export default Content;
