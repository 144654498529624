import styles from './AdminLoginModal.module.scss';
import AdminLogin from 'components/AdminLogin/AdminLogin';
import AdminResetPassword from 'components/AdminResetPassword/AdminResetPassword';
import useStore from 'store';
import { AuthorizationContextProvider } from 'contextProvider';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@moonshineragency/ui/src/components/Modal/Modal';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import { navigate, Link as GatsbyLink } from 'gatsby';

const AdminLoginModal = ({ isLoginModalOpen = false, hidden = false }) => {
  const dialog = useDialogState();
  const store = useStore();
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
  const [modalHeader, setModalHeader] = useState('');

  const changeModalHeader = title => {
    setModalHeader(title);
  };

  useEffect(() => {
    if (isLoginModalOpen) {
      dialog.show();
    }
  }, [isLoginModalOpen]);

  useEffect(() => {
    if (!dialog.visible) {
      setIsPasswordForgotten(false);
    }

    if (!dialog.visible && isLoginModalOpen) {
      navigate('/');
    }
  }, [dialog.visible]);

  return (
    <div className={hidden ? styles.hidden : ''}>
      {!store.user && (
        <DialogDisclosure
          className={styles.loginWrapper}
          as={Button}
          theme="link"
          {...dialog}
        >
          <IconWrapper
            Icon={IconComponents.LoginIcon}
            className={styles.loginIcon}
          />
          <span className={styles.login}>Login</span>
        </DialogDisclosure>
      )}
      {store.user && (
        <GatsbyLink
          className={styles.loginWrapper}
          as={Button}
          theme="link"
          to="/admin/trials/list"
        >
          <IconWrapper
            Icon={IconComponents.LoginIcon}
            className={styles.loginIcon}
          />
          <span className={styles.login}>Admin Dashboard</span>
        </GatsbyLink>
      )}

      <Modal theme="default" header={modalHeader} dialog={dialog}>
        {isPasswordForgotten ? (
          <AdminResetPassword
            setIsPasswordForgotten={setIsPasswordForgotten}
            changeModalHeader={changeModalHeader}
          />
        ) : (
          <AuthorizationContextProvider>
            <AdminLogin
              setIsPasswordForgotten={setIsPasswordForgotten}
              changeModalHeader={changeModalHeader}
              isDialogVisible={dialog.visible}
            />
          </AuthorizationContextProvider>
        )}
      </Modal>
    </div>
  );
};

AdminLogin.propTypes = {
  isLoginModalOpen: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default AdminLoginModal;
