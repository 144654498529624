import { useEffect } from 'react';

/**
 * Prevents scrolling on the document body on mount, and
 * restores it on unmount. Also ensures that content does not
 * shift due to the scrollbars disappearing.
 */
export function usePreventScroll(condition = false) {
  useEffect(() => {
    const { overflow } = document.body.style;
    const { paddingRight } = document.body.style;
    if (typeof window === `undefined`) {
      return;
    }
    if (condition) {
      document.body.style.paddingRight = `${
        window.innerWidth - document.documentElement.clientWidth
      }px`;
      document.body.style.overflow = 'hidden';
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.style.overflow = overflow;
      document.body.style.paddingRight = paddingRight;
    };
  }, [condition]);
}
